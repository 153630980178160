import Module from 'lib/common/models/module';
import Syllabus from '../../../lib/common/models/syllabus';

import {
  CLEAR_LIST_STATE,
  UPDATE_LIST_FILTERS,
  UPDATE_LIST_PAGINATION,
  UPDATE_LIST_SORTING,
  UPDATE_LIST_SYLLABUS,
  UPDATE_LIST_MODULE,
} from '../actionTypes';
import { Pagination } from 'store/reducers/listsReducer';

export type ListActionTypes =
  | UpdateListSyllabusAction
  | UpdateListModuleAction
  | UpdateListFiltersAction
  | UpdateListPaginationAction
  | UpdateListSortingAction
  | ClearListStateAction;

export interface UpdateListSyllabusAction {
  type: typeof UPDATE_LIST_SYLLABUS;
  payload: {
    syllabus: Syllabus;
    listName: string;
  };
}

export function updateListSyllabus(
  syllabus: Syllabus,
  listName: string,
): ListActionTypes {
  return {
    type: UPDATE_LIST_SYLLABUS,
    payload: {
      syllabus: syllabus,
      listName: listName,
    },
  };
}

export interface UpdateListModuleAction {
  type: typeof UPDATE_LIST_MODULE;
  payload: {
    module: Module | null;
    listName: string;
  };
}

export function updateListModule(
  module: Module | null,
  listName: string,
): ListActionTypes {
  return {
    type: UPDATE_LIST_MODULE,
    payload: { module, listName },
  };
}

export interface UpdateListFiltersAction {
  type: typeof UPDATE_LIST_FILTERS;
  payload: {
    filters: any;
    listName: string;
  };
}

export function updateListFilters(
  filters: any,
  listName: string,
): ListActionTypes {
  return {
    type: UPDATE_LIST_FILTERS,
    payload: {
      filters: filters,
      listName: listName,
    },
  };
}

export interface UpdateListPaginationAction {
  type: typeof UPDATE_LIST_PAGINATION;
  payload: {
    pagination: Pagination;
    listName: string;
  };
}

export function updateListPagination(
  pagination: Pagination,
  listName: string,
): ListActionTypes {
  return {
    type: UPDATE_LIST_PAGINATION,
    payload: {
      pagination: pagination,
      listName: listName,
    },
  };
}

export interface UpdateListSortingAction {
  type: typeof UPDATE_LIST_SORTING;
  payload: {
    sorting: any;
    listName: string;
  };
}

export function updateListSorting(
  sorting: any,
  listName: string,
): ListActionTypes {
  return {
    type: UPDATE_LIST_SORTING,
    payload: {
      sorting: sorting,
      listName: listName,
    },
  };
}

export interface ClearListStateAction {
  type: typeof CLEAR_LIST_STATE;
  payload: {
    listName: string;
  };
}

export function clearListState(listName: string): ListActionTypes {
  return {
    type: CLEAR_LIST_STATE,
    payload: {
      listName: listName,
    },
  };
}
