import React from 'react';
import { useDispatch } from 'react-redux';

import { DropdownButton, Dropdown } from 'react-bootstrap';
import {
  sortDateTable,
  DEFAULT_TIMEZONE_FORMAT,
  formatDateTime,
} from '../../../../shared/utils/dateTime';
import Settings from '../../../../lib/settings';
import ApiCaller from '../../../../lib/ApiCaller';
import { downloadMarkerReportByExamId } from '../../../../shared/utils/ApiCommands';
import {
  NavigationExamStatus,
  ScriptIntentions,
} from '../../../../lib/common/models/navigationExam';
import MarkerApi from '../../../../api/MarkerApi';
import NavigationExamApi from '../../../../api/NavigationExamApi';
import {
  downloadInvoiceReceipt,
  downloadExamByNavigationExamId,
} from '../../../../shared/utils/ApiCommands';
import {
  setCandidates,
  setMarkerGrid,
  setQuestions,
  setResults,
} from 'store/actions/marker/markerActions';
import {UserTypesLabels} from "../../../../lib/common/models/user";

export interface ListTableView {
  history: any;
  setStatus: any;
  layout: string;
}

export interface ListTable {
  getColumns(): any;
  getName(): string;
}

class ClosedExamListTable implements ListTable {
  loggedUser: any;
  view: ListTableView;
  navigationExamApi: NavigationExamApi;
  markerApi: MarkerApi;
  dispatch: any;

  constructor(profile: any, view: ListTableView) {
    this.loggedUser = profile;
    this.view = view;
    this.navigationExamApi = new NavigationExamApi(
      new ApiCaller(profile.token),
    );
    this.markerApi = new MarkerApi(new ApiCaller(profile.token));
    this.dispatch = useDispatch();
  }

  viewExam(cell: any) {
    const exam = cell.row.original;
    Settings.setCurrentNavigationExam(exam);
    this.view.history.push(`/${this.view.layout}/closed_exam/detail`);
  }

  getName(): string {
    return 'Closed Exams';
  }

  viewResults(cell: any) {
    const exam = cell.row.original;
    Settings.setCurrentNavigationExam(exam);
    this.view.history.push(`/${this.view.layout}/closed_exam/results`);
  }

  downloadMarkerReport(row: any) {
    downloadMarkerReportByExamId(
      row.row.original.id,
      `MARKER_REPORT_${row.row.original.getExamNumber()}`,
      this.loggedUser.token,
    );
  }

  markExam = (cell: any): void => {
    const exam = cell.row.original;
    Settings.setCurrentNavigationExam(exam);
    this.markerApi.getMarkerInitialData(exam.id).then((msg: any) => {
      this.dispatch(setCandidates(msg.candidates));
      this.dispatch(setQuestions(msg.questions));
      this.dispatch(setMarkerGrid(msg.grid));
      this.dispatch(setResults(msg.results));
      this.view.history.push(`/${this.view.layout}/navigation_exam/marks`);
    });
  };

  getColumns(): any {
    return [
      ...this.getCustomUserColumns(),
      {
        Header: 'ACTIONS',
        Cell: (row: any) => (
          <DropdownButton variant="default" title="Actions" size="sm">
            <Dropdown.Item onClick={() => this.viewExam(row)}>
              <i className="nc-icon nc-settings" />
              &nbsp;&nbsp;VIEW
            </Dropdown.Item>
            { (this.loggedUser.type!=='examCentre'&& this.loggedUser.type!=='treasurer' && this.loggedUser.type!=='examCentreAuditor') &&
              <Dropdown.Item onClick={() => this.markExam(row)}>
                <i className="nc-icon nc-settings-gear-65" />
                &nbsp;&nbsp;MARKS
              </Dropdown.Item> }
            <Dropdown.Item onClick={() => this.downloadMarkerReport(row)}>
              <i className="nc-icon nc-cloud-download-93" />
              &nbsp;&nbsp;DOWNLOAD MARKER REPORT
            </Dropdown.Item>
            {(row.row.original.isDeadlineReached() ||
              this.loggedUser.isPaymentAdmin()) && (
              <Dropdown.Item
                onClick={() =>
                  downloadInvoiceReceipt(
                    row.row.original,
                    this.loggedUser.token,
                  )
                }>
                <i className="nc-icon nc-cloud-download-93" />
                &nbsp;&nbsp;DOWNLOAD INVOICE
              </Dropdown.Item>
            )}
            {row.row.original.examId &&
              (row.row.original.isReadyForDownload() ||
                this.loggedUser.hasHistoricMarksPermission()) && (
                <Dropdown.Item
                  onClick={() =>
                    downloadExamByNavigationExamId(
                      row.row.original.id,
                      row.row.original.getExamNumber(),
                      this.loggedUser.token,
                    )
                  }>
                  <i className="nc-icon nc-cloud-download-93" />
                  &nbsp;&nbsp;DOWNLOAD EXAM
                </Dropdown.Item>
              )}
            { (this.loggedUser.hasHistoricMarksPermission() || this.loggedUser.isType(UserTypesLabels.examCentre)) && (
                <Dropdown.Item onClick={() => this.viewResults(row)}>
                  <i className="nc-icon nc-single-copy-04" />
                  &nbsp;&nbsp;RESULTS
                </Dropdown.Item>
            )}
          </DropdownButton>
        ),
      },
    ];
  }

  getCustomUserColumns() {
    const cols = [
      {
        Header: 'EXAM NUMBER',
        accessor: (d: any) => (d ? d.getExamNumber() : '-'),
        sortable: true,
      },
      {
        Header: 'EXAM CENTRE',
        accessor: (d: any) =>
          d.examCenterId
            ? `(${d.examCenterId.code}) ${d.examCenterId.name}`
            : '',
        sortable: true,
      },
      {
        Header: 'DATE',
        accessor: (d: any) => formatDateTime(d.date, DEFAULT_TIMEZONE_FORMAT),
        sortable: true,
        sortType: (a: any, b: any) => sortDateTable(a, b, 'date'),
      },
      {
        Header: 'SCRIPT INTENTION',
        accessor: (d: any) =>
          d.scriptIntention ? ScriptIntentions[d.scriptIntention] : '',
        sortable: true,
      },
      {
        Header: 'STATUS',
        accessor: (d: any) => (d.status ? NavigationExamStatus[d.status] : ''),
        sortable: true,
      },
    ];
    return cols;
  }
}

export default ClosedExamListTable;
