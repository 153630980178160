import React, { FC, useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { DropdownButton, Dropdown } from 'react-bootstrap';
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Row,
  CardTitle,
  Button,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import Select from 'react-select';

import ReactTable from '../../../../components/ReactTable/ReactTable';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../store/store';
import ApiCaller from '../../../../lib/ApiCaller';
import {
  DEFAULT_TIMEZONE_FORMAT,
  formatDateTime,
  sortDateTable,
} from '../../../../shared/utils/dateTime';
import MYDocument, {
  MYDocumentStatus,
} from '../../../../lib/common/models/myDocument';
import { stateListNames } from 'store/reducers/listsReducer';
import { setCurrentDoc } from 'store/actions/docs/docsActions';
import {
  useClearListStateOnPageChange,
  useSyllabus,
  useTableControls,
} from 'hooks';

import 'react-toastify/dist/ReactToastify.css';
import User from '../../../../lib/common/models/user';
import MYDocumentCollection from '../../../../lib/common/models/myDocumentCollection';
import ExamDocApi from '../../../../api/ExamDocApi';
import ConfirmationModal from 'views/components/confirmationModal/confirmationModal';
import ModuleApi from 'api/ModuleApi';
import ModuleCollection from 'lib/common/models/moduleCollection';
import {
  updateListSyllabus,
  updateListModule,
} from 'store/actions/lists/listsActions';
import NavigationExamPoolRelatedExamsTable from './navigationExamPoolRelatedExamsTable';

import './style.css';
import { sendToWritePDF } from 'shared/utils/ApiCommands';
import LoadingSpinner from 'views/components/loadingSpinner/loadingSpinner';

const getActionsMap = (user: User, status?: MYDocumentStatus) => ({
  canEdit: user.hasAdminRole() && status === MYDocumentStatus.EDITING,
  canSetReady: user.hasAdminRole() && status === MYDocumentStatus.EDITING,
  canReturnToEdit: user.hasAdminRole() && status === MYDocumentStatus.READY,
  canArchive:
    user.hasAdminRole() &&
    (status === MYDocumentStatus.READY || status === MYDocumentStatus.IN_USE),
  canDelete:
    user.isAdmin() &&
    (status === MYDocumentStatus.EDITING || status === MYDocumentStatus.READY),
});

const NavigationExamPoolList: FC = () => {
  const listName = stateListNames.examPool;
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);

  const moduleApi = new ModuleApi(new ApiCaller(loggedUser.token));

  const { syllabus } = useSyllabus();
  const selectedSyllabus = useSelector(
    (state: AppState) => state.lists[listName].syllabus,
  );
  const selectedModule = useSelector(
    (state: AppState) => state.lists[listName].module,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [tip, setTip] = useState<string>('');
  const [modules, setModules] = useState<ModuleCollection>(
    new ModuleCollection([]),
  );
  const [selectedDocument, setSelectedDocument] = useState<
    MYDocument | undefined
  >();

  const [isRelatedExamsModalOpen, setIsRelatedExamsModalOpen] = useState(false);
  const [isArchiveConfirmationPopupOpen, setIsArchiveConfirmationPopupOpen] =
    useState(false);
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] =
    useState(false);

  const [docs, setDocs] = useState<MYDocumentCollection>(
    new MYDocumentCollection([]),
  );

  useClearListStateOnPageChange(listName, [
    '/exam_pool/list',
    '/exam_pool/create',
    '/exam_pool/edit',
  ]);

  const {
    filters,
    sorting,
    pagination,
    onChangeFilters,
    onChangeSorting,
    onChangePagination,
  } = useTableControls(listName);

  const history = useHistory();
  const dispatch = useDispatch();

  const onSyllabusChange = (selectedSyllabus: any) => {
    dispatch(updateListSyllabus(selectedSyllabus, listName));
    if (selectedSyllabus._id) {
      moduleApi
        .getAllModules(selectedSyllabus._id, true)
        .then((modules: any) => {
          setModules(modules);
          dispatch(updateListModule(null, listName));
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const onModuleChange = (selectedModule: any) => {
    dispatch(updateListModule(selectedModule, listName));
  };

  const loadDrafts = () => {
    if (selectedModule?._id) {
      const examDocApi = new ExamDocApi(new ApiCaller());
      examDocApi
        .getExamPoolDrafts(selectedModule._id, loggedUser.token)
        .then((docs) => {
          setDocs(docs);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const changeDraftStatus = (draftId: string, status: MYDocumentStatus) => {
    setLoading(true);
    setTip('Updating status...');
    const examDocApi = new ExamDocApi(new ApiCaller());
    examDocApi
      .changeExamPoolDraftStatus(draftId, status, loggedUser.token)
      .then(() => {
        toast.success('Status has been successfully updated');
        loadDrafts();
      })
      .catch((err) => {
        toast.error(err.message);
      }).finally(() => {
        setLoading(false);
        setTip('');
      });
  };

  const handleEdit = (document: MYDocument) => {
    dispatch(setCurrentDoc(new MYDocument(document)));
    history.push(`/${layout}/exam_pool/edit`);
  };

  const handleSetReady = (document: MYDocument) => {
    changeDraftStatus(document.id, MYDocumentStatus.READY);
  };

  const handleReturnToEdit = (document: MYDocument) => {
    changeDraftStatus(document.id, MYDocumentStatus.EDITING);
  };

  const handleArchive = (document: MYDocument) => {
    setIsArchiveConfirmationPopupOpen(false);
    changeDraftStatus(document.id, MYDocumentStatus.ARCHIVED);
  };

  const handlePreviewDownload = (document: MYDocument) => {
    setLoading(true);
    setTip('Generating PDF...');
    sendToWritePDF(document)
      .then((isPdfGenerated) => {
        if (!isPdfGenerated) {
          toast.error('There was an error creating the PDF file for the exam');
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (document: MYDocument) => {
    setIsDeleteConfirmationPopupOpen(false);
    const examDocApi = new ExamDocApi(new ApiCaller());
    examDocApi
      .deleteExamPoolDraft(document.id, loggedUser.token)
      .then(() => {
        toast.success('Draft has been successfully deleted');
        loadDrafts();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    loadDrafts();
  }, [selectedModule]);

  const columns = [
    {
      Header: 'SERIAL NO.',
      accessor: (data: MYDocument) =>
        data.serialNumber ? data.serialNumber : '---',
      sortable: true,
    },
    {
      Header: 'STATUS',
      accessor: (data: MYDocument) => data.status,
      sortable: true,
    },
    {
      Header: 'USAGE COUNT',
      accessor: (data: MYDocument) => data.usageCount ?? 0,
      sortable: true,
    },
    {
      Header: 'LAST EDIT',
      accessor: (data: MYDocument) =>
        formatDateTime(data.updatedAt, DEFAULT_TIMEZONE_FORMAT),
      sortable: true,
      sortType: (a: any, b: any) => sortDateTable(a, b, 'updatedAt'),
    },
    {
      Header: 'ACTIONS',
      Cell: (row: any) => {
        const document: MYDocument = row.row.original;
        const actionsMap = getActionsMap(loggedUser, document.status);
        return (
          <DropdownButton variant="default" title="Actions" size="sm">
            {actionsMap.canEdit && (
              <Dropdown.Item
                style={{ margin: 0 }}
                onClick={() => handleEdit(document)}>
                <i className="nc-icon nc-settings" />
                &nbsp;&nbsp;EDIT
              </Dropdown.Item>
            )}
            {actionsMap.canSetReady && (
              <Dropdown.Item onClick={() => handleSetReady(document)}>
                <i className="nc-icon nc-check-2" />
                &nbsp;&nbsp;SET READY
              </Dropdown.Item>
            )}
            {actionsMap.canReturnToEdit && (
              <Dropdown.Item onClick={() => handleReturnToEdit(document)}>
                <i className="nc-icon nc-refresh-69" />
                &nbsp;&nbsp;RETURN TO EDIT
              </Dropdown.Item>
            )}
            {actionsMap.canArchive && (
              <Dropdown.Item
                onClick={() => {
                  setSelectedDocument(document);
                  setIsArchiveConfirmationPopupOpen(true);
                }}>
                <i className="nc-icon nc-app" />
                &nbsp;&nbsp;ARCHIVE
              </Dropdown.Item>
            )}
            {actionsMap.canDelete && (
              <Dropdown.Item
                onClick={() => {
                  setSelectedDocument(document);
                  setIsDeleteConfirmationPopupOpen(true);
                }}>
                <i className="nc-icon nc-simple-delete" />
                &nbsp;&nbsp;DELETE
              </Dropdown.Item>
            )}
            <Dropdown.Item onClick={() => handlePreviewDownload(document)}>
              <i className="nc-icon nc-cloud-download-93" />
              &nbsp;&nbsp;PDF PREVIEW
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                setSelectedDocument(document);
                setIsRelatedExamsModalOpen(true);
              }}
              disabled={(document.usageCount ?? 0) === 0}>
              <i className="nc-icon nc-bullet-list-67" />
              &nbsp;&nbsp;RELATED EXAMS
            </Dropdown.Item>
          </DropdownButton>
        );
      },
    },
  ];

  return (
    <>
      <div className="content">
        <LoadingSpinner spinning={loading} tip={tip}>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Exam Drafts Pool</CardTitle>
                </CardHeader>
                <Col sm="10">
                  <FormGroup>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="syllabus"
                      value={selectedSyllabus}
                      onChange={onSyllabusChange}
                      options={syllabus}
                      getOptionLabel={(item) => item.code + ' ' + item.title}
                      getOptionValue={(item) => item.code}
                      placeholder="Choose a syllabus"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="module"
                      value={selectedModule}
                      onChange={onModuleChange}
                      options={modules.modules}
                      getOptionLabel={(item) => item.code + ' ' + item.title}
                      getOptionValue={(item) => item.code}
                      placeholder="Choose a module"
                    />
                  </FormGroup>
                </Col>

                <CardBody>
                  <Row>
                    <Col>
                      <Button
                        color="info"
                        onClick={() =>
                          history.push(`/${layout}/exam_pool/create`)
                        }>
                        <span>
                          <i className="nc-icon nc-simple-add" />
                        </span>
                        Create
                      </Button>
                    </Col>
                  </Row>
                  {!!selectedModule && (
                    <ReactTable
                      data={docs.documents}
                      columns={columns}
                      initialFilters={filters}
                      onChangeFilters={onChangeFilters}
                      initialSorting={sorting}
                      onChangeSorting={onChangeSorting}
                      initialPagination={pagination}
                      onChangePagination={onChangePagination}
                    />
                  )}
                  {(!selectedSyllabus || !selectedModule) && (
                    <h5 className="card-category">
                      Please, select a syllabus and module to view the
                      information.
                    </h5>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {isDeleteConfirmationPopupOpen && selectedDocument && (
            <ConfirmationModal
              title="Deletion confirmation"
              onConfirm={() => handleDelete(selectedDocument)}
              onCancel={() => setIsDeleteConfirmationPopupOpen(false)}
              text={`Are you sure you want to delete ${
                selectedDocument.serialNumber ?? 'document'
              }?`}
            />
          )}
          {isArchiveConfirmationPopupOpen && selectedDocument && (
            <ConfirmationModal
              title="Archiving confirmation"
              onConfirm={() => handleArchive(selectedDocument)}
              onCancel={() => setIsArchiveConfirmationPopupOpen(false)}
              text={`Are you sure you want to archive ${
                selectedDocument.serialNumber ?? 'document'
              }?`}
            />
          )}
          <Modal
            className="modal-pool-related-exams"
            isOpen={isRelatedExamsModalOpen}
            toggle={() => setIsRelatedExamsModalOpen(!isRelatedExamsModalOpen)}>
            <ModalHeader>
              <button
                aria-hidden={true}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => setIsRelatedExamsModalOpen(false)}>
                <i className="nc-icon nc-simple-remove" />
              </button>
              {selectedDocument?.serialNumber} Related Exams
            </ModalHeader>
            <ModalBody>
              <NavigationExamPoolRelatedExamsTable
                examPoolDraft={selectedDocument}
              />
            </ModalBody>
          </Modal>
          <ToastContainer />
        </LoadingSpinner>
      </div>
    </>
  );
};

export default NavigationExamPoolList;
