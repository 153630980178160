import React, { useState } from 'react';
import { toast, ToastContainer } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { Button, Card, CardBody, Label, FormGroup, Form, Input, Row, Col, CardHeader, } from "reactstrap";

import Settings from "../../../../lib/settings";
import { AppState } from "../../../../store/store";

import ApiCaller from "../../../../lib/ApiCaller";

import Module from 'lib/common/models/module';
import ModuleApi from 'api/ModuleApi';

const ModuleDetail: React.FC = () => {
    const loggedUser = useSelector((state: AppState) => state.session.userInfo);
    const history = useHistory();
    const [currentModule, setCurrentModule] = useState<Module>();

    React.useEffect(() => {
        setCurrentModule(Settings.getCurrentModule());
    }, []);

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        const moduleInfo = currentModule?.toObject();

        if (moduleInfo) {
            moduleInfo[name] = value;
            setCurrentModule(new Module(moduleInfo));
        }
    };

    const handleCheckboxChange = (e: any) => {
      const { name, checked } = e.target;
      const moduleInfo = currentModule?.toObject();

      if (moduleInfo) {
        moduleInfo[name] = checked;
        setCurrentModule(new Module(moduleInfo));
      }
    };

    const updateModule = (evt: any) => {
        evt.preventDefault();
        if (currentModule) {
            currentModule.examPoolEnabled = !!currentModule.examPoolEnabled ? currentModule.examPoolEnabled : false;
            var moduleApi = new ModuleApi(new ApiCaller());
            moduleApi.updateModule(currentModule, loggedUser.token).then((module: any) => {
                history.push('/navozyme/module/list');
                toast.info('Updated successfully');
            }).catch(err => {
                console.log(err);
                toast.error('Error updating call number, please try again');
            });
        }
    }

    return (
        <>
            <div className="content">
                {currentModule &&
                    <Card>
                        <Row>
                            <Col md="12">
                                <CardHeader>
                                    <CardHeader><h4 className="card-title">Update Module</h4></CardHeader>
                                </CardHeader>
                                <CardBody>
                                    <Form className="form-horizontal" onSubmit={updateModule}>
                                        <Row>
                                            <Label sm="2">Code</Label>
                                            <Col sm="10">
                                                <FormGroup>
                                                    <Input
                                                        value={currentModule.code}
                                                        onChange={handleChange}
                                                        type="text"
                                                        name="code"
                                                        placeholder="Code"
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">Title</Label>
                                            <Col sm="10">
                                                <FormGroup>
                                                    <Input
                                                        value={currentModule.title}
                                                        onChange={handleChange}
                                                        type="text"
                                                        name="title"
                                                        placeholder="Title"
                                                        required
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">Exam Pool</Label>
                                            <Col sm="10" >
                                                <FormGroup>
                                                    <Input
                                                        checked={currentModule.examPoolEnabled ?? false}
                                                        onChange={handleCheckboxChange}
                                                        type="checkbox"
                                                        name="examPoolEnabled"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2"></Label>
                                            <Col sm="10">
                                                <Button className="btn-round" color="info" type="submit">
                                                    Update
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Col>
                        </Row>
                    </Card>
                }
            </div>
        </>
    )
}

export default ModuleDetail;
