import ApiCaller from '../lib/ApiCaller';
import NavigationExam from '../lib/common/models/navigationExam';
import Exam from '../lib/common/models/exam';
import NavigationExamCollection from '../lib/common/models/navigationExamCollection';
import CandidateCollection from '../lib/common/models/candidateCollection';
import MYDocument from '../lib/common/models/myDocument';
import Candidate from '../lib/common/models/candidate';
import ExamGradeCollection from '../lib/common/models/examGradeCollection';
import ExamGrade from '../lib/common/models/examGrade';
import ExamResource from '../lib/common/models/examResource';
import ExamStatusLog from 'lib/common/models/examStatusLog';
import ExamStatusLogCollection from 'lib/common/models/examStatusLogCollection';
import * as Sentry from "@sentry/react";

class NavigationExamApi {
  apiCaller: ApiCaller;

  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  create(
    syllabus: any,
    module: any,
    date: Date,
    scriptIntention: string,
    transactionId: string,
    token?: string,
  ): Promise<void | NavigationExam> {
    const params = {
      syllabus: syllabus._id,
      module: module._id,
      date: date,
      scriptIntention: scriptIntention,
      transactionId: transactionId,
    };
    return this.apiCaller
      .call('/v1/navigationExam/add', 'POST', params, token)
      .then((data) => {
        return new NavigationExam(data);
      });
  }

  checkExamRequestsLimit(
    syllabus: any,
    module: any,
    date: Date,
  ): Promise<void | boolean> {
    return this.apiCaller
      .call(
        `/v1/navigationExam/checkExamRequestsLimit?syllabusId=${
          syllabus._id
        }&moduleId=${module._id}&date=${date.toDateString()}`,
        'GET',
      )
      .then((data) => data.isLimitExceeded)
      .catch((err) => {
        console.error(err);
        alert('Failed to check exam requests limit.');
      });
  }

  getAll(): Promise<void | NavigationExamCollection> {
    return this.apiCaller
      .call('/v1/navigationExam/getAllExams', 'GET')
      .then((data) => {
        const examArr = data.navigationExams.map((exam: any) => {
          return new NavigationExam(exam);
        });

        return new NavigationExamCollection(examArr);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getById(examId: string): Promise<NavigationExam> {
    return this.apiCaller
      .call(`/v1/navigationExam/exam/${examId}`, 'GET')
      .then((data) => {
        return new NavigationExam(data);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getAllActiveExams(): Promise<void | NavigationExamCollection> {
    return this.apiCaller
      .call('/v1/navigationExam/getAllActiveExams', 'GET')
      .then((data) => {
        const examArr = data.navigationExams.map((exam: any) => {
          return new NavigationExam(exam);
        });
        return new NavigationExamCollection(examArr);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getResultsAll(): Promise<void | NavigationExamCollection> {
    return this.apiCaller
      .call('/v1/navigationExam/getAllExams?status=RELEASED', 'GET')
      .then((data) => {
        const examArr = data.navigationExams.map((exam: any) => {
          return new NavigationExam(exam);
        });
        return new NavigationExamCollection(examArr);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getClosedExams(): Promise<void | NavigationExamCollection> {
    return this.apiCaller
      .call('/v1/navigationExam/getAllExams?status=CLOSED', 'GET')
      .then((data) => {
        const examArr = data.navigationExams.map((exam: any) => {
          return new NavigationExam(exam);
        });
        return new NavigationExamCollection(examArr);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getRequestedExams(): Promise<void | NavigationExamCollection> {
    return this.apiCaller
      .call(
        '/v1/navigationExam/getAllExams?status=REJECTED&status=PROVISIONAL',
        'GET',
      )
      .then((data) => {
        const examArr = data.navigationExams.map((exam: any) => {
          return new NavigationExam(exam);
        });

        return new NavigationExamCollection(examArr);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getScheduledExams(): Promise<void | NavigationExamCollection> {
    return this.apiCaller
      .call(
        '/v1/navigationExam/getAllExams?status=SCHEDULED&status=PAID',
        'GET',
      )
      .then((data) => {
        const examArr = data.navigationExams.map((exam: any) => {
          return new NavigationExam(exam);
        });
        return new NavigationExamCollection(examArr);
      })
      .catch((err) => {
        console.error(err);
        //throw Error(err);
      });
  }
  getAllScheduledExams(params: any): Promise<void | NavigationExamCollection> {
    const filter = new URLSearchParams(params).toString();
    return this.apiCaller
      .call(`/v1/navigationExam/getAllScheduledExams?${filter}`, 'GET')
      .then((data) => {
        const examArr = data.navigationExams.map((exam: any) => {
          return new NavigationExam(exam);
        });
        return new NavigationExamCollection(examArr);
      })
      .catch((err) => {
        console.error(err);
        //throw Error(err);
      });
  }

  getCancelledExams(): Promise<void | NavigationExamCollection> {
    return this.apiCaller
      .call('/v1/navigationExam/getAllExams?status=CANCELLED', 'GET')
      .then((data) => {
        const examArr = data.navigationExams.map((exam: any) => {
          return new NavigationExam(exam);
        });
        return new NavigationExamCollection(examArr);
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  getMarkedPendingExams(): Promise<void | NavigationExamCollection> {
    // when user is marker, get only their exams
    return this.apiCaller
      .call('/v1/navigationExam/getAllExams?status=GENERATED', 'GET')
      .then((data) => {
        const examArr = data.navigationExams.map((exam: any) => {
          return new NavigationExam(exam);
        });
        return new NavigationExamCollection(
          examArr.filter((exam: any) => exam.candidates.length > 0),
        );
      })
      .catch((err) => {
        console.error(err);
        //throw Error(err);
      });
  }

  getMarkedMCAPendingExams(): Promise<void | NavigationExamCollection> {
    return this.apiCaller
      .call('/v1/navigationExam/getAllMCAExams', 'GET')
      .then((data) => {
        const examArr = data.navigationExams.map((exam: any) => {
          return new NavigationExam(exam);
        });
        return new NavigationExamCollection(
          examArr.filter((exam: any) => exam.candidates.length > 0),
        );
      })
      .catch((err) => {
        console.error(err);
        //throw Error(err);
      });
  }
  getMarkedExamAdminPendingExams(): Promise<void | NavigationExamCollection> {
    return this.apiCaller
      .call(
        '/v1/navigationExam/getAllExams?status=GENERATED&status=REVISION&status=REVISIONMCA',
        'GET',
      )
      .then((data) => {
        const examArr = data.navigationExams.map((exam: any) => {
          return new NavigationExam(exam);
        });
        return new NavigationExamCollection(
          examArr.filter((exam: any) => exam.candidates.length > 0),
        );
      })
      .catch((err) => {
        console.error(err);
        //throw Error(err);
      });
  }

  async getExamsByDraft(draftId: string): Promise<NavigationExamCollection> {
    return this.apiCaller
      .call(`/v1/navigationExam/getExamsByDraft/${draftId}`, 'GET')
      .then((data) => {
        const examArr = data.navigationExams.map((exam: any) => {
          return new NavigationExam(exam);
        });
        return new NavigationExamCollection(examArr);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  deleteNavigationExam(
    navigationExamId: string,
    token?: string,
  ): Promise<void> {
    return this.apiCaller
      .call(
        '/v1/rest/navigationExam/' + navigationExamId,
        'DELETE',
        {
          id: navigationExamId,
        },
        token,
      )
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  updateNavigationExam(
    navigationExam: NavigationExam,
    token?: string,
  ): Promise<void | NavigationExam> {
    return this.apiCaller
      .call(
        '/v1/navigationExam/update/' + navigationExam.id,
        'PUT',
        {
          syllabusId: navigationExam.syllabus._id,
          moduleId: navigationExam.module._id,
          date: navigationExam.date,
          numCandidates: navigationExam.numCandidates,
          scriptIntention: navigationExam.scriptIntention,
          firstMarkerId: navigationExam.firstMarker?._id,
          secondMarkerId: navigationExam.secondMarker?._id,
        },
        token,
      )
      .then((data) => {
        return new NavigationExam(data);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  revertToDraft(
    customName: string,
    token?: string,
  ): Promise<NavigationExam> {
    return this.apiCaller
      .call('/v1/documents/document/revert', 'POST', {customName}, token)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  cancel(exam: NavigationExam, token?: string): Promise<void | NavigationExam> {
    return this.apiCaller
      .call('/v1/navigationExam/cancel/' + exam.id, 'POST', {}, token)
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }

        return new NavigationExam(data);
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  }

  getDraftById(examId: string): Promise<void | any | undefined> {
    return this.apiCaller.call(
      `/v1/documents/draft/retrieve/${examId}`,
      'GET',
      undefined,
    );
  }

  getExamModelBySlug(slug: string): Promise<any> {
    return this.apiCaller.call(
      `/v1/documents/exam/retrieve/${slug}`,
      'GET',
      undefined,
    );
  }

  getDrafts(token?: string): Promise<void | any | undefined> {
    return this.apiCaller.call(`/v1/exam/drafts`, 'GET', undefined, token);
  }

  getArchives(token?: string): Promise<void | any | undefined> {
    return this.apiCaller.call(
      `/v1/exam/archives`,
      'GET',
      undefined,
      token,
    );
  }

  removeDraft(id: string, token?: string): Promise<void | any | undefined> {
    return this.apiCaller.call(
      `/v1/documents/draft/${id}`,
      'DELETE',
      undefined,
      token,
    );
  }

  launchDraft(id: string, token?: string): Promise<void | any | undefined> {
    return this.apiCaller.call(
      `/v1/documents/draft/${id}`,
      'PUT',
      undefined,
      token,
    );
  }


  releaseExam(examId: string, token?: string): Promise<void | any> {
    return this.apiCaller
      .call('/v1/navigationExam/release/' + examId, 'PUT', {}, token)
      .then(() => {
        return true;
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  closeExam(examId: string, token?: string): Promise<void | any> {
    return this.apiCaller
      .call('/v1/navigationExam/close/' + examId, 'PUT', {}, token)
      .then((data) => {
        if (data?.message) {
          throw Error(data.message);
        }
        return true;
      })
      .catch((err) => {
        console.error(err);
        throw Error(err.message);
      });
  }

  generateBasicDraftExam(examId: string, token?: string): Promise<void | any> {
    return this.apiCaller
      .call('/v1/navigationExam/generate/' + examId, 'PUT', {}, token)
      .then((data) => {
        return {
          doc: new MYDocument(data.doc),
          exam: new Exam(data.exam),
        };
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getCandidatesResults(
    navigationExamId: string,
  ): Promise<void | ExamGradeCollection> {
    return this.apiCaller
      .call('/v1/marker/results/' + navigationExamId, 'GET')
      .then((data) => {
        if (data.error) {
          throw Error(data.error);
        }
        return new ExamGradeCollection(
          data.grades.map((item: any) => new ExamGrade(item)),
        );
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getCandidateResult(
    navigationExamId: string,
    candidateId: string,
  ): Promise<void | ExamGradeCollection> {
    return this.apiCaller.call(
      '/v1/marker/results/' + navigationExamId + '/' + candidateId,
      'GET',
    );
  }

  getCandidates(navigationExamId: string): Promise<void | CandidateCollection> {
    return this.apiCaller
      .call('/v1/navigationExam/candidates/' + navigationExamId, 'GET')
      .then((data) => {
        const apiArr = data.candidates.map(
          (candidate: any) => new Candidate(candidate),
        );
        return new CandidateCollection(apiArr);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getExamStatuses(navigationExamId: string): Promise<void | ExamStatusLogCollection> {
    return this.apiCaller
      .call(`/v1/navigationExam/exam/${navigationExamId}/statuses`, 'GET')
      .then((data) => {
        const apiArr = data.statuses.map(
          (statusLog: any) => new ExamStatusLog(statusLog),
        );
        return new ExamStatusLogCollection(apiArr);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  addCandidate(
    navigationExamId: string,
    candidate: Candidate,
    token?: string,
  ): Promise<void | NavigationExam> {
    return this.apiCaller
      .call(
        '/v1/navigationExam/candidates/' + navigationExamId,
        'POST',
        candidate,
        token,
      )
      .then((data) => {
        return new NavigationExam(data);
      })
      .catch((err) => {
        Sentry.captureException(`Error adding candidate: ${JSON.stringify(err)}`);
        throw Error(err);
      });
  }

  removeCandidate(
    navigationExamId: string,
    candidate: Candidate,
    token?: string,
  ): Promise<void | NavigationExam> {
    return this.apiCaller
      .call(
        '/v1/navigationExam/candidates/' + navigationExamId,
        'DELETE',
        candidate,
        token,
      )
      .then((data) => {
        return new NavigationExam(data);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getReceipt(examId: string): Promise<void | any | undefined> {
    return this.apiCaller
      .download('/v1/navigationExam/create-receipt/' + examId, 'GET')
      .then((file) => {
        return file;
      });
  }

  async shuffleExam(
    documentId: string,
    token?: string,
  ): Promise<any> {
    return this.apiCaller
      .call(
        '/v1/navigationExam/shuffle',
        'POST',
        { documentId },
        token,
      )
      .then((data) => {
        return { doc: new MYDocument(data.doc), exam: new Exam(data.exam) };
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  shuffleExamQuestion(
    documentId: string,
    examId: string,
    questionId: string,
    topicId: string,
    token?: string,
  ): Promise<any> {
    return this.apiCaller
      .call(
        '/v1/navigationExam/shuffleQuestion',
        'POST',
        {
          documentId: documentId,
          examId: examId,
          questionId: questionId,
          topicId: topicId,
        },
        token,
      )
      .then((data) => {
        return { doc: new MYDocument(data.doc), exam: new Exam(data.exam) };
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  removeExamQuestion(
    documentId: string,
    examId: string,
    questionName: string,
    token?: string,
  ): Promise<{doc:MYDocument, exam: Exam}> {
    return this.apiCaller
      .call(
        '/v1/navigationExam/removeQuestion',
        'POST',
        {
          documentId: documentId,
          examId: examId,
          questionName: questionName,
        },
        token,
      )
      .then((data) => {
        return { doc: new MYDocument(data.doc), exam: new Exam(data.exam) };
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  removeExamResource(
    examId: string,
    resourceId: string,
    token?: string,
  ): Promise<any> {
    return this.apiCaller
      .call(
        '/v1/navigationExam/removeResource',
        'POST',
        {
          examId,
          resourceId,
        },
        token,
      )
      .then((data) => {
        return new Exam(data);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  addExamResources(
    examId: string,
    resources: ExamResource[],
    token?: string,
  ): Promise<any> {
    return this.apiCaller
      .call(
        '/v1/navigationExam/addResources',
        'POST',
        {
          examId,
          resources: resources.map((resource) => resource.id),
        },
        token,
      )
      .then((data) => {
        return new Exam(data);
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  getCloneableExams(
    navigationExamId: string,
  ): Promise<NavigationExamCollection> {
    return this.apiCaller
      .call('/v1/navigationExam/clone/' + navigationExamId, 'GET')
      .then((data) => {
        if (data.error) throw Error(data.error);
        else {
          const apiArr = data.navigationExams
            ? data.navigationExams.map(
                (model: any) => new NavigationExam(model),
              )
            : [];
          return new NavigationExamCollection(apiArr);
        }
      })
      .catch((err) => {
        console.log(err.message);
        return err;
      });
  }

  cloneExam(
    navigationExamId: string,
    examId: string,
    token?: string,
  ): Promise<Exam> {
    return this.apiCaller
      .call(
        '/v1/navigationExam/clone/' + navigationExamId,
        'POST',
        {
          examId: examId,
        },
        token,
      )
      .then((data) => {
        if (data.error) {
          throw new Error(data.error);
        }

        return new Exam(data);
      })
      .catch((err) => {
        console.log(err.message);
        throw err;
      });
  }
}

export default NavigationExamApi;
