import ApiCaller from '../lib/ApiCaller';
import MYDocument, { MYDocumentStatus } from '../lib/common/models/myDocument';
import MYDocumentCollection from '../lib/common/models/myDocumentCollection';

class ExamDocApi {
  apiCaller: ApiCaller;
  constructor(apiCaller: ApiCaller) {
    this.apiCaller = apiCaller;
  }

  getCertificatesRaw(user: string): Promise<void | any | undefined> {
    return this.apiCaller.call(`/v1/documents/document/${user}`, 'GET');
  }

  getDraftRaw(user: string): Promise<void | any | undefined> {
    return this.apiCaller.call(`/v1/documents/draft/${user}`, 'GET');
  }
  removeDraft(id: string): Promise<void | any | undefined> {
    return this.apiCaller.call(`/v1/exam/removedraft/${id}`, 'DELETE');
  }

  getDocsRaw(country: string): Promise<void | any | undefined> {
    return this.apiCaller
      .call(`/v1/exam/getAllModels/${country}`, 'GET')
      .then((data) => {
        return {
          models: data.models.map((m: any) => {
            return m;
          }),
        };
      });
  }

  search(term: string, country?: string): Promise<void | any | undefined> {
    return this.apiCaller
      .call(`/v1/exam/search/${term}/${country}`, 'GET')
      .then((data) => {
        return {
          models: data.models.map((m: any) => {
            return m;
          }),
        };
      });
  }

  getModelBySlug(slug: string): Promise<any> {
    return this.apiCaller
      .call(`/v1/exam/getModelBySlug/${slug}`, 'GET')
      .then((data) => {
        data.examContent.vars = data.examContent.vars.map((v: string) => {
          return { key: v, value: '' };
        });
        return data;
      });
  }

  /**
   * @deprecated not used, requires replacing slug by MYDocument id
   */
  sendToWriteDoc(
    content: string,
    slug: string,
  ): Promise<void | any | undefined> {
    return this.apiCaller
      .download('/v1/exam/sendToWriteDoc', 'POST', {
        base: content,
        slug: slug,
      })
      .then((file) => {
        return file;
      });
  }

  async sendToWritePDF(
    documentId: string,
    content?: string,
  ): Promise<void | any | undefined> {
    return this.apiCaller
      .download('/v1/exam/sendToWritePDF', 'POST', {
        base: content,
        documentId,
      })
      .then((file) => {
        return file;
      });
  }

  createPDFWithCertificate(
    content: string,
    documentId: string
  ): Promise<void | any | undefined> {
    return this.apiCaller
      .call('/v1/exam/createPDFWithCertificate', 'POST', {
        base: content,
        documentId
      })
      .then((file) => {
        return file;
      });
  }

  updateDraft(
    draftId: string,
    content: string,
    slug: string,
    userId: string,
    vars: any[],
    customName?: string,
    customDescription?: string,
    date?: Date,
  ): Promise<void | any | undefined> {
    const params = {
      base: content,
      slug: slug,
      userId: userId,
      customName: customName,
      customDescription: customDescription,
      date: date,
      vars: vars,
    };
    return this.apiCaller
      .call('/v1/exam/createDraft/' + draftId, 'POST', params)
      .then((file) => {
        return file;
      });
  }

  async downloadExamByNavigationExamId(
    navigationExamId: string,
  ): Promise<void | any | undefined> {
    return this.apiCaller
      .download('/v1/exam/download/exam/' + navigationExamId, 'GET')
      .then((file) => {
        return file;
      });
  }

  downloadExamByDocumentId(id: string): Promise<void | any | undefined> {
    return this.apiCaller
      .download('/v1/exam/download/' + id, 'GET')
      .then((file) => {
        return file;
      });
  }

  downloadAnswers(id: string): Promise<void | any | undefined> {
    return this.apiCaller
      .download('/v1/exam/download/answers/' + id, 'GET')
      .then((file) => {
        return file;
      });
  }

  async getExamPoolDrafts(
    moduleId?: string,
    token?: string,
  ): Promise<MYDocumentCollection> {
    const queryParamsString = moduleId ? `?moduleId=${moduleId}` : '';
    return this.apiCaller
      .call(
        `/v1/exam/examPoolDrafts${queryParamsString}`,
        'GET',
        undefined,
        token,
      )
      .then((data) => {
        return new MYDocumentCollection(
          data.documents.map((doc: any) => new MYDocument(doc)),
        );
      })
      .catch((err) => {
        console.error(err);
        throw Error(err);
      });
  }

  async changeExamPoolDraftStatus(
    draftId: string,
    status: MYDocumentStatus,
    token?: string,
  ): Promise<MYDocument> {
    return this.apiCaller
      .call(
        `/v1/exam/examPoolDraft/${draftId}/changeStatus`,
        'PUT',
        { status },
        token,
      )
      .then((data) => {
        if (data.message) {
          throw new Error(data.message);
        }
        return new MYDocument(data);
      });
  }

  async createExamPoolDraft(
    syllabusId: string,
    moduleId: string,
    token?: string,
  ): Promise<MYDocument> {
    return this.apiCaller
      .call(
        '/v1/exam/examPoolDraft',
        'POST',
        { syllabusId, moduleId },
        token,
      )
      .then((data) => {
        if (data.message) {
          throw new Error(data.message);
        }
        return new MYDocument(data);
      });
  }

  async deleteExamPoolDraft(draftId: string, token?: string): Promise<void> {
    return this.apiCaller
      .call(`/v1/exam/examPoolDraft/${draftId}`, 'DELETE', undefined, token)
      .then((data) => {
        if (data.message) {
          throw new Error(data.message);
        }
        return;
      });
  }
}

export default ExamDocApi;
