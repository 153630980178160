import Module from 'lib/common/models/module';
import Syllabus from '../../lib/common/models/syllabus';

import {
  CLEAR_LIST_STATE,
  UPDATE_LIST_FILTERS,
  UPDATE_LIST_PAGINATION,
  UPDATE_LIST_SORTING,
  UPDATE_LIST_SYLLABUS,
  UPDATE_LIST_MODULE,
} from 'store/actions/actionTypes';
import { ListActionTypes } from 'store/actions/lists/listsActions';
import { ListLabels } from 'views/pages/admin/question/listTypes';

const DEFAULT_PAGINATION: Pagination = {
  pageSize: 10,
  pageIndex: 0,
};

export const stateListNames = {
  examList: 'examList',
  questionList: ListLabels.user,
  questionBank: ListLabels.questionBank,
  archivedQuestions: ListLabels.archivedQuestions,
  examResources: 'examResources',
  examDrafts: 'examDrafts',
  examPool: 'examPool',
  completedExams: 'completedExams',
  cancelledExams: 'cancelledExams',
  pendingExams: 'pendingExams',
  candidateList: 'candidateList',
};

export type ListState = {
  filters?: any;
  pagination: Pagination;
  sorting?: any;
};

export type ListWithSyllabusState = ListState & {
  syllabus?: Syllabus;
};

export type ListWithSyllabusAndModuleState = ListState & {
  syllabus?: Syllabus;
  module?: Module;
};

export type ListsState = {
  examList: ListWithSyllabusState;
  questionList: ListWithSyllabusState;
  questionBank: ListWithSyllabusState;
  archivedQuestions: ListWithSyllabusState;
  examResources: ListState;
  examDrafts: ListState;
  examPool: ListWithSyllabusAndModuleState;
  completedExams: ListWithSyllabusState;
  cancelledExams: ListState;
  pendingExams: ListState;
  candidateList: ListState;
};

export type Pagination = {
  pageSize: number;
  pageIndex: number;
};

const initialState: ListsState = {
  examList: {
    pagination: DEFAULT_PAGINATION,
  },
  questionList: {
    pagination: DEFAULT_PAGINATION,
  },
  questionBank: {
    pagination: DEFAULT_PAGINATION,
  },
  archivedQuestions: {
    pagination: DEFAULT_PAGINATION,
  },
  examResources: {
    pagination: DEFAULT_PAGINATION,
  },
  examDrafts: {
    pagination: DEFAULT_PAGINATION,
  },
  examPool: {
    pagination: DEFAULT_PAGINATION,
  },
  completedExams: {
    pagination: DEFAULT_PAGINATION,
  },
  cancelledExams: {
    pagination: DEFAULT_PAGINATION,
  },
  pendingExams: {
    pagination: DEFAULT_PAGINATION,
  },
  candidateList: {
    pagination: DEFAULT_PAGINATION,
  },
};

export function listsReducer(
  state = initialState,
  action: ListActionTypes,
): ListsState {
  switch (action.type) {
    case UPDATE_LIST_SYLLABUS: {
      const listName = action.payload.listName;

      return {
        ...state,
        [listName]: {
          ...state[listName],
          syllabus: action.payload.syllabus,
        },
      };
    }
    case UPDATE_LIST_MODULE: {
      const listName = action.payload.listName;

      return {
        ...state,
        [listName]: {
          ...state[listName],
          module: action.payload.module,
        },
      };
    }
    case UPDATE_LIST_FILTERS: {
      const listName = action.payload.listName;

      return {
        ...state,
        [listName]: {
          ...state[listName],
          filters: action.payload.filters,
        },
      };
    }
    case UPDATE_LIST_PAGINATION: {
      const listName = action.payload.listName;
      return {
        ...state,
        [listName]: {
          ...state[listName],
          pagination: action.payload.pagination,
        },
      };
    }
    case UPDATE_LIST_SORTING: {
      const listName = action.payload.listName;
      return {
        ...state,
        [listName]: {
          ...state[listName],
          sorting: action.payload.sorting,
        },
      };
    }
    case CLEAR_LIST_STATE: {
      const listName = action.payload.listName;
      return {
        ...state,
        [listName]: {
          filters: undefined,
          pagination: DEFAULT_PAGINATION,
          sorting: undefined,
          syllabus: undefined,
          module: undefined,
        },
      };
    }
    default:
      return state;
  }
}
