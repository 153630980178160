import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Col,
  Row,
  CardTitle,
} from 'reactstrap';

import { AppState } from 'store/store';
import ApiCaller from 'lib/ApiCaller';
import { generateErrors, is } from 'shared/utils/validations';
import Syllabus from 'lib/common/models/syllabus';
import Module from 'lib/common/models/module';
import DetailLayout from 'layouts/DetailLayout';
import { useSyllabus } from 'hooks';
import ModuleApi from 'api/ModuleApi';
import ModuleCollection from 'lib/common/models/moduleCollection';

import 'react-toastify/dist/ReactToastify.css';
import ExamDocApi from 'api/ExamDocApi';
import { setCurrentDoc } from 'store/actions/docs/docsActions';

interface ExamPoolDraftCreateForm {
  syllabus: Syllabus | null;
  module: Module | null;
}

const NavigationExamPoolCreate: React.FC = () => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const layout = useSelector((state: AppState) => state.session.layout);
  const history = useHistory();
  const dispatch = useDispatch();

  const moduleApi = new ModuleApi(new ApiCaller(loggedUser.token));

  const { syllabus } = useSyllabus();
  const [modules, setModules] = useState<Module[]>([]);

  const [form, setForm] = useState<ExamPoolDraftCreateForm>({
    syllabus: null,
    module: null,
  });

  const checkErrors = () => {
    const syllabus = form.syllabus;
    const module = form.module;

    const errors: any = generateErrors(
      { syllabus, module },
      {
        syllabus: [is.required()],
        module: [is.required()],
      },
    );

    if (errors.syllabus) toast.error(errors.syllabus);
    if (errors.module) toast.error(errors.module);
    return Object.keys(errors).length !== 0;
  };

  const onSyllabusChange = (syllabus: any) => {
    setForm({
      ...form,
      module: null,
      syllabus,
    });
    
    moduleApi
      .getAllModules(syllabus._id, true)
      .then((data: ModuleCollection) => {
        setModules(data.modules);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onModuleChange = (e: any) => {
    setForm({
      ...form,
      module: e,
    });
  };

  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (checkErrors() || !form.syllabus?._id || !form.module?._id) {
      return;
    }

    const examDocApi = new ExamDocApi(new ApiCaller());
    examDocApi
      .createExamPoolDraft(
        form.syllabus._id,
        form.module._id,
        loggedUser.token
      )
      .then((document) => {
        dispatch(setCurrentDoc(document));
        history.replace(`/${layout}/navigation_exam/edit`);
      })
      .catch((err: any) => {
        console.log(err);
        toast.error(err.message);
      });
  };
  
  return (
    <div className="content">
      <DetailLayout title="Go back">
        <Card>
          <Row>
            <Col md="12">
              <CardHeader>
                <CardTitle tag="h4">New Exam Pool Draft</CardTitle>
              </CardHeader>
              <CardBody>
                <Form className="form-horizontal" onSubmit={formSubmit}>
                  <Row>
                    <Label sm="2">Syllabus</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="syllabus"
                          value={form.syllabus}
                          onChange={onSyllabusChange}
                          options={syllabus}
                          getOptionLabel={(item) =>
                            item.code + ' ' + item.title
                          }
                          getOptionValue={(item) => item.code}
                          placeholder="Choose a syllabus"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2">Module</Label>
                    <Col sm="10">
                      <FormGroup>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="module"
                          value={form.module}
                          onChange={onModuleChange}
                          options={modules}
                          getOptionLabel={(item) =>
                            item.code + ' ' + item.title
                          }
                          getOptionValue={(item) => item.code}
                          placeholder="Choose a module"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Label sm="2" />
                    <Col sm="10">
                      <Button
                        className="btn-round"
                        color="info"
                        type="submit"
                        disabled={!form.syllabus || !form.module}>
                        Create
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Col>
          </Row>
        </Card>
        <ToastContainer />
      </DetailLayout>
    </div>
  );
};

export default NavigationExamPoolCreate;
