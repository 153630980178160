import Dashboard from '../../views/UserDashboard';
import UserList from '../../views/pages/admin/user/userList';
import UserCreate from '../../views/pages/admin/user/userCreate';
import UserDetail from '../../views/pages/admin/user/userDetail';
import QuestionUserList from '../../views/pages/admin/question/questionUserList';
import QuestionBankList from '../../views/pages/admin/question/questionBankList';
import ArchivedQuestionsList from '../../views/pages/admin/question/archivedQuestionsList';
import QuestionCreate from '../../views/pages/admin/question/questionCreate';
import QuestionDetail from '../../views/pages/admin/question/questionDetail';
import CreateSyllabus from '../../views/pages/admin/syllabus/createSyllabus';
import SyllabusList from '../../views/pages/admin/syllabus/syllabusList';
import SyllabusDetail from '../../views/pages/admin/syllabus/syllabusDetail';
import ModuleCreate from '../../views/pages/admin/module/moduleCreate';
import ModuleList from '../../views/pages/admin/module/moduleList';
import ModuleDetail from '../../views/pages/admin/module/moduleDetail';
import TopicCreate from '../../views/pages/admin/topics/topicCreate';
import TopicList from '../../views/pages/admin/topics/topicList';
import TopicDetail from '../../views/pages/admin/topics/topicDetail';
import NavigationExamCreate from '../../views/pages/admin/navigation_exam/navigationExamCreate';
import NavigationExamDetail from '../../views/pages/admin/navigation_exam/navigationExamDetail';
import ImageGallery from '../../views/pages/imageGallery/ImageGallery';
import ExamEditor from '../../views/pages/ExamEditor';
import TrackQuestions from '../../views/pages/admin/track/trackQuestions';
import ExamMark from '../../views/pages/ExamMark';
import NavigationExamPending from '../../views/pages/admin/navigation_exam/navigationExamPending';
import TransactionList from '../../views/pages/admin/transactions/transactionList';
import TransactionCreate from '../../views/pages/admin/transactions/transactionCreate';
import PaymentList from '../../views/pages/admin/transactions/paymentList';
import NavigationExamSettings from '../../views/pages/admin/navigation_exam/navigationExamSettings';
import NavigationExamList from '../../views/pages/admin/navigation_exam/navigationExamList';
import ExamCenterList from '../../views/pages/admin/examCenter/examCenterList';
import ExamCenterCreate from '../../views/pages/admin/examCenter/examCenterCreate';
import ExamCenterDetail from '../../views/pages/admin/examCenter/examCenterDetail';
import CandidateDetail from '../../views/pages/admin/candidate/candidateDetail';
import CandidateList from '../../views/pages/admin/candidate/candidateList';
import CandidateWithReportList from '../../views/pages/admin/candidate/candidateWithReportList';
import NavigationExamDraftList from '../../views/pages/admin/navigation_exam_draft/navigationExamDraft';
import CancelledExamList from '../../views/pages/admin/cancelled_exam/cancelledExamList';
import ExamCenterFinanceDetail from '../../views/pages/admin/examCenter/examCenterFinanceDetail';
import NavigationExamClone from '../../views/pages/admin/navigation_exam/navigationExamClone';
import ExamDocumentArchive from '../../views/pages/admin/examDocument/examDocumentArchive';
import ExamResourceList from '../../views/pages/admin/exam_resources/examResourceList';
import ExamResourceCreate from '../../views/pages/admin/exam_resources/examResourceCreate';
import ExamResourceDetail from '../../views/pages/admin/exam_resources/examResourceDetail';
import CompletedExamList from '../../views/pages/admin/completed_exam/completedExamList';
import NavigationExamResults from '../../views/pages/admin/completed_exam/navigationExamResults';
import CertificateList from '../../views/pages/admin/certificate/certificateList';
import CertificateDetail from '../../views/pages/admin/certificate/certificateDetail';
import MessageSend from '../../views/pages/admin/notifications/messageSend';
import ProfileResetPassword from '../../views/pages/common/profile/ProfileResetPassword';
import ClosedExamList from '../../views/pages/admin/closed_exam/closedExamList';
import ClosedExamDetail from '../../views/pages/admin/closed_exam/closedExamDetail';
import ClosedExamResults from '../../views/pages/admin/closed_exam/closedExamResults';
import QuestionPipeline from '../../views/pages/admin/question/questionPipeline';
import CalendarExamList from "../../views/pages/admin/calendar/ExamList";
import NavigationExamPoolList from '../../views/pages/admin/navigation_exam_pool/navigationExamPoolList';
import NavigationExamPoolCreate from '../../views/pages/admin/navigation_exam_pool/navigationExamPoolCreate';

export const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-bank',
    component: Dashboard,
  },
  {
    collapse: true,
    name: 'Questions',
    icon: 'nc-icon nc-book-bookmark',
    state: 'questionCollapse',
    views: [
      {
        path: '/question/list',
        name: 'Question List',
        mini: 'QL',
        component: QuestionUserList,
      },
      {
        path: '/question/bank',
        name: 'Question Bank',
        mini: 'QB',
        component: QuestionBankList,
      },
      {
        path: '/question/archived',
        name: 'Archived Questions',
        mini: 'QA',
        component: ArchivedQuestionsList,
      },
      {
        path: '/question/track',
        name: 'Question Track',
        mini: 'QT',
        component: TrackQuestions,
      },
      {
        path: '/question/pipeline',
        name: 'Question Pipeline',
        mini: 'QP',
        component: QuestionPipeline,
      },
      {
        path: '/exam_resource/list',
        name: 'Exam Resources',
        mini: 'ER',
        component: ExamResourceList,
      },
    ],
  },
  {
    collapse: true,
    name: 'Exams',
    icon: 'nc-icon nc-book-bookmark',
    state: 'navigationCollapse',
    views: [
      {
        path: '/exams/calendar',
        name: 'Exam Calendar',
        mini: 'CC',
        component: CalendarExamList,
      },
      {
        path: '/navigation_exam/list',
        name: 'Exam Requests',
        mini: 'EL',
        component: NavigationExamList,
      },
      {
        path: '/completed_exam/list',
        name: 'Results Declared',
        mini: 'ER',
        component: CompletedExamList,
      },
      {
        path: '/exam_pool/list',
        name: 'Exam Drafts Pool',
        mini: 'EP',
        component: NavigationExamPoolList,
      },
      {
        path: '/navigation_exam/generated/list',
        name: 'Exam Drafts',
        mini: 'ED',
        component: NavigationExamDraftList,
      },
      {
        path: '/examDocument/archive/list',
        name: 'Exam Archive',
        mini: 'EA',
        component: ExamDocumentArchive,
      },
      {
        path: '/cancelled_exam/list',
        name: 'Cancelled exams',
        mini: 'EC',
        component: CancelledExamList,
      },
      {
        path: '/closed_exam/list',
        name: 'Closed Exams',
        mini: 'CE',
        component: ClosedExamList,
      },
      {
        path: '/navigation_exam/settings',
        name: 'Settings',
        mini: 'ES',
        component: NavigationExamSettings,
      },
    ],
  },
  {
    collapse: true,
    name: 'Topology',
    icon: 'nc-icon nc-book-bookmark',
    state: 'syllabusCollapse',
    views: [
      {
        path: '/syllabus/list',
        name: 'Syllabus',
        mini: 'TS',
        component: SyllabusList,
      },
      {
        path: '/module/list',
        name: 'Modules',
        mini: 'TM',
        component: ModuleList,
      },
      {
        path: '/topic/list',
        name: 'Topics',
        mini: 'TT',
        component: TopicList,
      },
    ],
  },
  {
    path: '/gallery/collection',
    name: 'Images',
    icon: 'nc-icon nc-image',
    component: ImageGallery,
  },
  {
    collapse: true,
    name: 'Marks',
    icon: 'nc-icon nc-book-bookmark',
    state: 'marksCollapse',
    views: [
      {
        path: '/navigation_exam/marked',
        name: 'Pending Exams',
        mini: 'ES',
        component: NavigationExamPending,
      },
      {
        path: '/navigation_exam/marks',
        name: 'Current Exam',
        mini: 'EM',
        component: ExamMark,
      },
    ],
  },
  {
    path: '/user/list',
    name: 'Users',
    icon: 'nc-icon nc-user-run',
    component: UserList,
  },
  {
    collapse: true,
    name: 'Candidates',
    icon: 'nc-icon nc-single-02',
    state: 'candidatesCollapse',
    views: [
      {
        path: '/candidate/list',
        name: 'Candidates list',
        mini: 'CL',
        component: CandidateList,
      },
      {
        path: '/candidateWithReports/list',
        name: 'Candidate reports',
        mini: 'CR',
        component: CandidateWithReportList,
      },
    ],
  },
  {
    path: '/examCenter/list',
    name: 'Exam Centers',
    icon: 'nc-icon nc-shop',
    component: ExamCenterList,
  },
  {
    path: '/notifications/send_message',
    name: 'Notifications',
    icon: 'nc-icon nc-send',
    component: MessageSend,
  },
  {
    collapse: true,
    name: 'Maintenance',
    icon: 'nc-icon nc-settings-gear-65',
    state: 'maintenanceCollapse',
    views: [
      {
        path: '/databases',
        name: 'Databases',
        mini: 'DB',
        component: UserCreate,
      },
    ],
  },
  {
    collapse: true,
    name: 'Payments',
    icon: 'nc-icon nc-money-coins',
    state: 'paymentsCollapse',
    views: [
      {
        path: '/payments/history',
        name: 'Payments History',
        mini: 'PH',
        component: PaymentList,
      },
      {
        path: '/transactions/history',
        name: 'Transactions History',
        mini: 'TH',
        component: TransactionList,
      },
    ],
  },
  {
    path: '/certificate/list',
    name: 'Certificates',
    icon: 'nc-icon nc-money-coins',
    component: CertificateList,
  },
];

export const hiddenRoutes = [
  {
    path: '/question/create',
    name: 'Create Question',
    mini: 'CC',
    component: QuestionCreate,
  },
  {
    path: '/exam_resource/create',
    name: 'Create Exam Resource',
    mini: 'CR',
    component: ExamResourceCreate,
  },
  {
    path: '/exam_resource/detail',
    name: 'Exam Resource Detail',
    mini: 'RD',
    component: ExamResourceDetail,
  },
  {
    path: '/question/detail',
    name: 'Question Detail',
    mini: 'CD',
    component: QuestionDetail,
  },
  {
    path: '/user/detail',
    name: 'User Detail',
    mini: 'UD',
    component: UserDetail,
  },
  {
    path: '/navigation_exam/request',
    name: 'New Exam Request',
    mini: 'EC',
    component: NavigationExamCreate,
  },
  {
    path: '/navigation_exam/detail',
    name: 'Exam Request Detail',
    mini: 'ED',
    component: NavigationExamDetail,
  },
  {
    path: '/navigation_exam/clone',
    name: 'Exam Clone',
    mini: 'EC',
    component: NavigationExamClone,
  },
  {
    path: '/navigation_exam/edit',
    name: 'Exam Editor',
    mini: 'EE',
    component: ExamEditor,
  },
  {
    path: '/exam_pool/create',
    name: 'Exam Pool Editor',
    mini: 'EP',
    component: NavigationExamPoolCreate,
  },
  {
    path: '/exam_pool/edit',
    name: 'Exam Pool Editor',
    mini: 'EP',
    component: ExamEditor,
  },
  {
    path: '/closed_exam/detail',
    name: 'Closed Exam Detail',
    mini: 'ED',
    component: ClosedExamDetail,
  },
  {
    path: '/closed_exam/results',
    name: 'Closed Exam Results',
    mini: 'ER',
    component: ClosedExamResults,
  },
  {
    path: '/syllabus/create',
    name: 'Add New',
    mini: 'AN',
    component: CreateSyllabus,
  },
  {
    path: '/syllabus/detail',
    name: 'Syllabus Detail',
    mini: 'SD',
    component: SyllabusDetail,
  },
  {
    path: '/module/create',
    name: 'Add New',
    mini: 'AN',
    component: ModuleCreate,
  },
  {
    path: '/module/detail',
    name: 'Module Detail',
    mini: 'SD',
    component: ModuleDetail,
  },
  {
    path: '/topic/create',
    name: 'Add New',
    mini: 'AN',
    component: TopicCreate,
  },
  {
    path: '/topic/detail',
    name: 'Topic Detail',
    mini: 'SD',
    component: TopicDetail,
  },
  {
    path: '/user/create',
    name: 'User Create',
    mini: 'UC',
    component: UserCreate,
  },
  {
    path: '/examCenter/create',
    name: 'Exam Center Create',
    mini: 'EC',
    component: ExamCenterCreate,
  },
  {
    path: '/examCenter/detail',
    name: 'Exam Center Detail',
    mini: 'ED',
    component: ExamCenterDetail,
  },
  {
    path: '/examCenter/transactions',
    name: 'Exam Center Detail',
    mini: 'ED',
    component: ExamCenterFinanceDetail,
  },
  {
    path: '/payments/create',
    name: 'Create Transaction',
    mini: 'TC',
    component: TransactionCreate,
  },
  {
    path: '/candidate/detail',
    name: 'Candidate Detail',
    mini: 'CD',
    component: CandidateDetail,
  },
  {
    path: '/navigation_exam/results',
    name: 'Exam Results',
    mini: 'ER',
    component: NavigationExamResults,
  },
  {
    path: '/certificate/detail',
    name: 'Certificate Detail',
    mini: 'CD',
    component: CertificateDetail,
  },
  {
    path: '/recovery-password/confirm/:token?',
    name: 'Reset Password',
    mini: 'RP',
    component: ProfileResetPassword,
  },
];
