import React, { FC, useEffect, useState } from 'react';

import { Table } from 'reactstrap';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store/store';
import ApiCaller from '../../../../lib/ApiCaller';
import {
  DEFAULT_TIMEZONE_FORMAT,
  formatDateTime,
} from '../../../../shared/utils/dateTime';
import MYDocument from '../../../../lib/common/models/myDocument';

import 'react-toastify/dist/ReactToastify.css';
import NavigationExamApi from 'api/NavigationExamApi';
import NavigationExamCollection from 'lib/common/models/navigationExamCollection';
import NavigationExam from 'lib/common/models/navigationExam';

type NavigationExamPoolRelatedExamsTableProps = {
  examPoolDraft?: MYDocument;
};

const NavigationExamPoolRelatedExamsTable: FC<
  NavigationExamPoolRelatedExamsTableProps
> = ({ examPoolDraft }) => {
  const loggedUser = useSelector((state: AppState) => state.session.userInfo);
  const [exams, setExams] = useState<NavigationExamCollection>(
    new NavigationExamCollection([]),
  );

  const navigationExamApi = new NavigationExamApi(
    new ApiCaller(loggedUser.token),
  );

  useEffect(() => {
    if (examPoolDraft) {
      navigationExamApi
        .getExamsByDraft(examPoolDraft.id)
        .then((data) => {
          setExams(data);
        })
        .catch((err) => console.error(err));
    }
  }, [examPoolDraft]);

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>SERIAL NO.</th>
          <th>DATE</th>
          <th>EXAM CENTER</th>
        </tr>
      </thead>
      <tbody>
        {exams.navigationExams.map((exam: NavigationExam) => (
          <tr key={exam.id}>
            <td>{exam.displayName}</td>
            <td>{formatDateTime(exam.date, DEFAULT_TIMEZONE_FORMAT)}</td>
            <td>{`(${exam.examCenterId?.code}) ${exam.examCenterId?.name}`}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default NavigationExamPoolRelatedExamsTable;
