import MYDocument from './myDocument';

class MYDocumentCollection {
  documents: Array<MYDocument>;

  constructor(documents: Array<MYDocument>) {
    this.documents = documents;
  }

  length() {
    return this.documents.length;
  }
}

export default MYDocumentCollection;
