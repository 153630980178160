import {NZDocumentType} from "./nzDocument";

interface MYDocumentInfo {
  id?: string;
  _id?: string;
  createdBy?: string;
  sourceSlug?: string;
  type?: NZDocumentType;
  customName?: string;
  customDescription?: string;
  expirationDate?: Date;
  status?: MYDocumentStatus;
  displayName?: string;
  description?: string;
  createdAt?: Date;
  updatedAt?: Date;
  content?: string;
  vars?: Array<MyDocumentVars>;
  serialNumber?: string;
  syllabusId?: string;
  moduleId?: string;
  usageCount?: number;
}

export interface MyDocumentVars{
  key:string;
  value:string;
}

export enum MYDocumentStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  DONE = 'DONE',
  ARCHIVED = 'ARCHIVED',
  EDITING = 'EDITING',
  IN_USE = 'IN_USE',
  READY = 'READY',
}

class MYDocument {
  id: string;
  createdBy?: string;
  sourceSlug?: string;
  type?: NZDocumentType;
  customName?: string;
  customDescription?: string;
  expirationDate?: Date;
  status?: MYDocumentStatus;
  displayName?: string;
  description?: string;
  createdAt?: Date;
  updatedAt?: Date;
  content?: string;
  vars?: Array<MyDocumentVars>;
  serialNumber?: string;
  syllabusId?: string;
  moduleId?: string;
  usageCount?: number;

  constructor(file: MYDocumentInfo) {
    this.id = file._id || file.id || '';
    this.createdBy = file.createdBy;
    this.sourceSlug = file.sourceSlug;
    this.type = file.type;
    this.customName = file.customName;
    this.customDescription = file.customDescription;
    this.expirationDate = file.expirationDate;
    this.status = file.status;
    this.displayName = file.displayName;
    this.description = file.description;
    this.createdAt = file.createdAt;
    this.updatedAt = file.updatedAt;
    this.content = file.content;
    this.vars = file.vars;
    this.serialNumber = file.serialNumber;
    this.syllabusId = file.syllabusId;
    this.moduleId = file.moduleId;
    this.usageCount = file.usageCount;
  }

  toObject(): MYDocumentInfo {
    return {
      id: this.id,
      createdBy: this.createdBy,
      sourceSlug: this.sourceSlug,
      type: this.type,
      customName: this.customName,
      customDescription: this.customDescription,
      expirationDate: this.expirationDate,
      status: this.status,
      displayName: this.displayName,
      description: this.description,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      content: this.content,
      vars: this.vars,
      serialNumber: this.serialNumber,
      syllabusId: this.syllabusId,
      moduleId: this.moduleId,
      usageCount: this.usageCount,
    };
  }
}

export default MYDocument;
