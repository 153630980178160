export const sortBooleanTable = (a: any, b: any, sortField: string) => {
    const a1 = a.original[sortField];
    const b1 = b.original[sortField];

    if (a1 && !b1) {
        return -1;
      } else if (!a1 && b1) {
        return 1;
      } else {
        return 0;
      }
};
